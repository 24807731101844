.prose pre code,
.highlight code {
    color: #ffffff; /* Lighter text color for contrast */
}

pre.highlight {
    padding: 16px;
    background-color: #000000; /* Black background */
}

.highlight .hll { background-color: #333333 } /* Highlight color on black */
.highlight .c { color: #999988; font-style: italic } /* Comment */
.highlight .err { color: #a61717; background-color: #e3d2d2 } /* Error */
.highlight .k { color: #ffffff; font-weight: bold } /* Keyword */
.highlight .o { color: #ffffff; font-weight: bold } /* Operator */
.highlight .cm { color: #999988; font-style: italic } /* Comment.Multiline */
.highlight .cp { color: #999999; font-weight: bold; font-style: italic } /* Comment.Preproc */
.highlight .c1 { color: #999988; font-style: italic } /* Comment.Single */
.highlight .cs { color: #999999; font-weight: bold; font-style: italic } /* Comment.Special */
.highlight .gd { color: #ffffff; background-color: #660000 } /* Generic.Deleted */
.highlight .ge { color: #ffffff; font-style: italic } /* Generic.Emph */
.highlight .gr { color: #ff0000 } /* Generic.Error */
.highlight .gh { color: #aaaaaa } /* Generic.Heading */
.highlight .gi { color: #ffffff; background-color: #006600 } /* Generic.Inserted */
.highlight .go { color: #888888 } /* Generic.Output */
.highlight .gp { color: #555555 } /* Generic.Prompt */
.highlight .gs { font-weight: bold } /* Generic.Strong */
.highlight .gu { color: #aaaaaa } /* Generic.Subheading */
.highlight .gt { color: #ff0000 } /* Generic.Traceback */
.highlight .kc { color: #ffffff; font-weight: bold } /* Keyword.Constant */
.highlight .kd { color: #ffffff; font-weight: bold } /* Keyword.Declaration */
.highlight .kn { color: #ffffff; font-weight: bold } /* Keyword.Namespace */
.highlight .kp { color: #ffffff; font-weight: bold } /* Keyword.Pseudo */
.highlight .kr { color: #ffffff; font-weight: bold } /* Keyword.Reserved */
.highlight .kt { color: #445588; font-weight: bold } /* Keyword.Type */
.highlight .m { color: #00cccc } /* Literal.Number */
.highlight .s { color: #ff3366 } /* Literal.String */
.highlight .na { color: #00cccc } /* Name.Attribute */
.highlight .nb { color: #00cccc } /* Name.Builtin */
.highlight .nc { color: #445588; font-weight: bold } /* Name.Class */
.highlight .no { color: #00cccc } /* Name.Constant */
.highlight .nd { color: #00cccc; font-weight: bold } /* Name.Decorator */
.highlight .ni { color: #cc00cc } /* Name.Entity */
.highlight .ne { color: #ff0000; font-weight: bold } /* Name.Exception */
.highlight .nf { color: #ff0000; font-weight: bold } /* Name.Function */
.highlight .nl { color: #ff0000; font-weight: bold } /* Name.Label */
.highlight .nn { color: #555555 } /* Name.Namespace */
.highlight .nt { color: #0000cc } /* Name.Tag */
.highlight .nv { color: #00cccc } /* Name.Variable */
.highlight .ow { color: #ffffff; font-weight: bold } /* Operator.Word */
.highlight .w { color: #bbbbbb } /* Text.Whitespace */
.highlight .mf { color: #00cccc } /* Literal.Number.Float */
.highlight .mh { color: #00cccc } /* Literal.Number.Hex */
.highlight .mi { color: #00cccc } /* Literal.Number.Integer */
.highlight .mo { color: #00cccc } /* Literal.Number.Oct */
.highlight .sb { color: #ff3366 } /* Literal.String.Backtick */
.highlight .sc { color: #ff3366 } /* Literal.String.Char */
.highlight .sd { color: #ff3366 } /* Literal.String.Doc */
.highlight .s2 { color: #ff3366 } /* Literal.String.Double */
.highlight .se { color: #ff3366 } /* Literal.String.Escape */
.highlight .sh { color: #ff3366 } /* Literal.String.Heredoc */
.highlight .si { color: #ff3366 } /* Literal.String.Interpol */
.highlight .sx { color: #ff3366 } /* Literal.String.Other */
.highlight .sr { color: #33ff66 } /* Literal.String.Regex */
.highlight .s1 { color: #ff3366 } /* Literal.String.Single */
.highlight .ss { color: #ff00cc } /* Literal.String.Symbol */
.highlight .bp { color: #00cccc } /* Name.Builtin.Pseudo */
.highlight .vc { color: #00cccc } /* Name.Variable.Class */
.highlight .vg { color: #00cccc } /* Name.Variable.Global */
.highlight .vi { color: #00cccc } /* Name.Variable.Instance */
.highlight .il { color: #00cccc } /* Literal.Number.Integer.Long */
